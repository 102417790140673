@import url(./assets/fonts/all-fonts.css);
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-background) !important;
}


/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


html,
body,
#root {
  width: 100%;
  scroll-behavior: smooth;
}

:root {

  /* blue default color  */
  /* --primary: #4880FF; */
  --primary: rgba(72, 128, 255, 0.9);
  --primary-light: rgba(72, 128, 255, 0.1317);
  --hover-primary-light: rgba(72, 127, 255, 0.046);


  /* green  */
  /* --primary: #00B69B;
  --primary-light: rgba(0, 182, 155, 0.204);
  --hover-primary-light: rgba(0, 182, 155, 0.057); */


  /* yellow  */
  /* --primary: #d7b607;
  --primary-light: #d7b40787;
  --hover-primary-light: #d7b40740; */


  /* Dark Blue  */
  /* --primary: #0832bf;
  --primary-light: #0833bf4a;
  --hover-primary-light: #0833bf20; */


  /* Red */
  /* --primary: #fc0000;
  --primary-light: #fc000056;
  --hover-primary-light: #fc00001f; */


  /* Pink  */
  /* --primary: #d90768;
  --primary-light: #d9076936;
  --hover-primary-light: #d907691d; */

  --int-extraLight: "NunitoSans200";
  --int-light: "NunitoSans300";
  --int-regular: "NunitoSans400";
  --int-medium: "NunitoSans500";
  --int-semibold: "NunitoSans600";
  --int-bold: "NunitoSans700";
  --int-extraBold: "NunitoSans800";
  --int-black: "NunitoSans900";
  --body-background: rgba(245, 246, 250, 1);
  --active-green: #66bb6a;
  --teal-card-light: rgba(1, 150, 130, 0.173);
  --red-error: #f44335;
  --orange: #f67374;
  --edit-green-light: rgba(0, 182, 155, 0.204);
  --orange-light: #f673732c;
  --yellow: #FEC53D;
  --yellow-light: #fec43d31;
  --teal-card: rgb(1 150 130 / 75%);
  --cancel-gray: #9e9e9e;
  --active-green-light: #e9f5ea;
  --red: #f50e02;
  --blue: #2896f3;
  --blue-light: #2898f325;
  --red-dark: #d64243;
  --red-error-light: #fde5e5;
  --edit-green: #00B69B;
  --purple: #6226EF;
  --purple-light: #6226ef27;
  --purple-light: #6226ef2e;
  --primary-view: #c5e4ff;
  --primary-gray: rgba(32, 32, 32, 0.532);
  --white: #ffffff;
  --gray-text: #202224;
  --light-border: #dfdfdf;
  --black: black;
  --gray-font: rgba(32, 34, 36, 0.6);
  --gray-font-light: rgba(32, 34, 36, 0.204);
  --amount-green-light: #cef0ce;
  --amount-green: #2e7d32;
  --yellow-dark-light: #ff88192e;
  --yellow-dark: #eb7f19;
  --blue-amount-light: #0198e429;
  --blue-amount: #0098e5;
  --violet-amount-light: #bb29ff36;
  --violet-amount: #BA29FF;
  --green-transfered-light: #e0ffe0;
  --green-transfered: #18a718;
  --lightblue-balance-light: #abe1f370;
  --lightblue-balance: #0098e5;
}

.commonShadow {
  box-shadow: 6px 6px 54px 0 rgba(0, 0, 0, 0.03) !important;
}

.commonShadowLight {
  box-shadow: 6px 6px 54px 0 rgba(0, 0, 0, 0.05) !important;
}

.commonBorderRadius,
table {
  border-radius: 14px !important;
}

.commonButtonRadius {
  border-radius: 6px !important;
}

.commonStatusRadius {
  border-radius: 4.5px !important;
}

/* //sidebar code  */
.selected-menu-items>ul>li>.ps-menu-button:hover {
  background-color: var(--primary-light) !important;
}

.selected-menu-items>ul>li>.ps-menu-button.ps-active {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  font-weight: 600 !important;
  font-family: var(--int-bold) !important;
}

.selected-menu-items>ul>li>.ps-menu-button.ps-menu-icon.ps-active {
  color: var(--white) !important;
}

.selected-menu-items>ul>li>.ps-menu-button.ps-menu-icon {
  color: var(--gray-font) !important;
}

.selected-menu-items>ul>li>.ps-menu-button {
  font-weight: 600 !important;
  font-family: var(--int-bold) !important;
  color: var(--gray-font) !important;
}

/* table data grid */
.tableHeaderColor {
  background: var(--white) !important;
}

.tableHeaderColor .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row {
  font-family: var(--int-medium) !important;
}

.userDataGridTable .MuiDataGrid-main .MuiDataGrid-virtualScroller {
  min-height: 610px !important;
}

.userDataGridTable .MuiDataGrid-main>div>div>div .MuiDataGrid-cell--withRenderer {
  min-height: 62px !important;
  max-height: 62px !important;
}

.userDataGridTable .MuiDataGrid-main>div>div>div>.MuiDataGrid-row {
  min-height: 62px !important;
  max-height: 62px !important;
}


.tableHeaderColor .MuiDataGrid-columnHeader,
table>thead>tr>th {
  background: var(--primary-light) !important;
  font-weight: 600 !important;
  font-family: var(--int-extraBold) !important;
}

.MuiDataGrid-row:hover,
table>tbody>tr:hover {
  background-color: var(--hover-primary-light) !important;
}


/* TextField Input field textarea pagination dateRangePicker */
.MuiTextField-root input,
.MuiInputBase-root input,
textarea,
.Mui-error,
.rdrDateRangePickerWrapper,
.MuiDataGrid-footerContainer .MuiTablePagination-root .MuiToolbar-root>p {
  font-family: var(--int-medium) !important;
}

/* material ui tabs  */
.MuiTabs-flexContainer {
  padding: 10px 0px;
}

.MuiTabs-flexContainer>button {
  border-left: 2px solid var(--light-border) !important;
  min-height: 32px !important;
  max-height: 24px !important;
}

.MuiTabs-flexContainer>button:first-child {
  border-left: 2px solid transparent !important;
}

.MuiTabs-flexContainer>button.Mui-selected {
  color: var(--primary) !important;
  font-family: var(--int-extraBold) !important;
}

.MuiTabs-flexContainer>button {
  font-family: var(--int-extraBold) !important;
  color: var(--gray-font) !important;
}

.MuiTabs-flexContainer,
.tabsRadius {
  background-color: var(--white) !important;
  border-radius: 6px !important;
}

.tabsScroll .MuiTabs-indicator,
.MuiTabs-indicator {
  background-color: var(--primary) !important;
}

.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus-within {
  outline: none !important;
  /* outline: solid var(--primary) 1px !important; */
}

.tabsRadius {
  overflow-x: auto !important;
}

.searchBox>div {
  background-color: var(--white) !important;
  height: 46px !important;
  font-family: var(--int-semibold) !important;

}

/* material ui button outline button */
.primaryButtonClass {
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  font-weight: 600 !important;
}

.addField-textarea textarea {
  max-height: 130px !important;
  overflow-y: auto !important;
}


.textOverflowLowLength {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

@media (max-width: 1600px) {
  .textOverflowLowLength {
    max-width: 130px;
  }
}

.textOverflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
}

.textOverflowTwoLines {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selecterFocus .Mui-focused,
.filterType>svg {
  color: var(--primary) !important;
}

.filterType>fieldset,
.reportSelectorFilterType>fieldset {
  border: 1px solid var(--primary) !important;
}

.filterType>div {
  padding: 8.5px 45px 8.5px 14px !important;
  min-width: 100px !important;
  width: fit-content !important;
  color: var(--primary) !important;
  font-weight: 600 !important;
}

.dataGridTable>div,
.dataGridTableFeaturedService>div {
  overflow-x: auto !important;
}

.dataGridTableFeaturedService>div>div {
  min-width: 900px !important;
}

.feedbackDatagrid .MuiDataGrid-columnHeaderTitle,
.alertToastDataGridTable .MuiDataGrid-columnHeaderTitle {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.tableHeaderColor .MuiDataGrid-columnHeaderTitle {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.alertToastDataGridTable {
  overflow: hidden !important;
  overflow-x: auto !important;
}

.dataGridTable>div>div {
  min-width: 600px !important;
}

.tabsScroll>div {
  overflow-x: auto !important;
}

.tabsScroll>div>div>button svg {
  margin-bottom: 0px !important;
  width: 18px !important;
  height: 18px !important;
}

.tabsScroll>div>div>button {
  display: flex !important;
  /* min-height: 48px !important; */
  align-items: center !important;
  gap: 6px !important;
  flex-direction: row !important;
}

@media (max-width: 768px) and (min-width: 720px) {
  .defaultBasicInfo {
    margin-top: 30px !important;
  }
}

.MuiAlert-icon {
  color: white !important;
}

.MuiAlert-message {
  font-weight: 600 !important;
}

.feedbackDatagrid>div>div>div>div>.MuiDataGrid-overlay {
  font-weight: 600 !important;
  font-size: 18px !important;
}

.reportTabs {
  font-weight: 600 !important;
  color: red !important;
}

.reportParentTab .MuiTabs-indicator {
  background-color: var(--primary) !important;
}

.selected-menu-items .ps-menu-button.ps-active {
  border-radius: 4px !important;
}

.oppurtunitiesGridTable .MuiDataGrid-virtualScroller {
  overflow-y: auto !important;
}

.featuredServiceDiologBox>.MuiDialog-scrollPaper>div {
  max-width: 520px !important;
  min-width: 300px !important;
}

.alertToastDataGrid>div>div>.MuiDataGrid-virtualScroller>div>div>div>div>div {
  word-wrap: break-word;
  white-space: break-spaces;
  max-height: 40px !important;
}

.alertToastDataGrid>div>div {
  min-width: 600px !important;
}

.tabsScroll>div {
  overflow-x: auto !important;
}

.alertToastDataGridTable .MuiDataGrid-cell--withRenderer {
  outline: none !important;
  outline-width: 0px !important;
  outline-offset: 0px !important;
}

.listingShadow {
  border: 2px solid transparent;
}

.selecterTransferMoneyPlace>div {
  padding: 10px 14px !important;
  min-height: 38px !important;
}

.iconRotate {
  transform: rotate(40deg);
}


html[lang="ar"] {
  direction: rtl;
}