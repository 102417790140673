table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  overflow: hidden;

  td,
  th {
    border-top: 1px solid #ECF0F1;
    padding: 10px;
  }

  td {
    border-left: 1px solid #ECF0F1;
    border-right: 1px solid #ECF0F1;
  }

  th {
    background-color: rgba(197, 228, 255, 1);
  }

  tr:nth-of-type(even) td {
    background-color: lighten(rgba(197, 228, 255, 1), 35%);
  }

  .total {
    th {
      background-color: white;
    }

    td {
      text-align: right;
      font-weight: 700;
    }
  }
}
